/*======================================
9. About Page
 =======================================*/

/* 9.1 wpo-mission-vission-section */

.wpo-mission-vission-section {
  .wpo-mission-item,
  .wpo-vission-item {
    padding: 50px;
    background: $dark-gray2;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 100%; //new style

    @media (max-width: 575px) {
      padding: 20px;
    }

    .shape-1 {
      position: absolute;
      left: -70px;
      bottom: -70px;
      width: 200px;
      height: 200px;
      background: $theme-primary-color;
      border-radius: 50%;
      z-index: -1;
    }

    .shape-2 {
      position: absolute;
      right: 0;
      top: 0;
    }

    .wpo-mission-text,
    .wpo-vission-text {
      h2 {
        color: $theme-primary-color;
        font-size: 34px;
        margin-bottom: 20px;
      }

      p {
        color: $light;
        font-size: 15px;
        margin-bottom: 20px;
      }

      h5 {
        color: $white;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  .wpo-vission-item {
    background-color: $section-bg-color-s2;

    @media (max-width: 991px) {
      margin-top: 30px;
    }
    .wpo-vission-text {
      p {
        color: #616161;
        margin-bottom: 0;
      }

      h5 {
        color: $dark-gray2;
        margin-bottom: 20px;
      }
    }
  }
}

/* 9.2 wpo-about-section-s4 */

.wpo-about-section-s4 {
  position: relative;
  overflow: hidden;

  @media (max-width: 991px) {
    padding-top: 0;
  }
  .wpo-about-inner {
    box-shadow: none;
    padding: 0;
    margin-top: 0;

    .wpo-about-text {
      padding-left: 0;
      @media (max-width: 991px) {
        padding-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  .shape-ab {
    position: absolute;
    right: -150px;
    bottom: -180px;
    width: 330px;
    height: 330px;
    background: $theme-primary-color;
    border-radius: 40px;
    transform: rotate(20deg);
    z-index: 110;

    @media (max-width: 767px) {
      right: -50px;
      bottom: -80px;
      width: 200px;
      height: 200px;
    }
    @media (max-width: 575px) {
      right: -50px;
      bottom: -80px;
      width: 160px;
      height: 160px;
    }

    &:before {
      position: absolute;
      left: -30px;
      top: 30px;
      width: 100%;
      height: 100%;
      border: 1px solid $dark-gray2;
      content: "";
      border-radius: 40px;
    }
  }

  .wpo-about-wrap {
    .wpo-about-img {
      max-width: 100%;
    }
  }
}

@keyframes dots {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

.item {
  font-size: 0.85rem;
  display: flex;
  gap: 12px;
  word-break: keep-all;
  margin-bottom: 1.5rem;
}

.item > span:first-child {
  font-size: 1rem;
  color: #04628c;
}

.item > span:last-child {
  display: inline-block;
  max-width: 350px;
}

.history-inner:nth-child(even) .item {
  flex-direction: row-reverse;
}

.history-guide {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
}
.history-guide:after {
  display: block;
  content: "";
  background: #ddd;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -2;
}

.history-inner {
  position: relative;
  margin-bottom: 50px;
}
.history-inner:after {
  content: "";
  display: block;
  clear: both;
}
.history-inner .his_img {
  float: left;
  width: 50%;
  box-sizing: Border-box;
  padding: 0px 0px 0px 60px;
}

.history-inner:nth-child(even) .his_img {
  float: right;
}
.history-inner:nth-child(odd) .his_img {
  text-align: right;
  padding: 0px 60px 0px 0px;
}
.history-inner .his_img img {
  max-width: 100%;
}

.history-inner:nth-child(odd) ul {
  float: right;
  text-align: left;
  padding: 0 0 0 60px;
}
.history-inner:nth-child(even) ul {
  float: left;
}
.history-inner ul {
  float: right;
  text-align: right;
  width: 50%;
  box-sizing: Border-box;
  padding: 0 60px 0 0;
}

.history-inner:nth-child(even) li.year {
  text-align: right;
}

.history-inner ul {
  position: relative;
  float: right;
  width: 50%;
  box-sizing: Border-box;
}
.history-inner ul:before {
  display: block;
  content: "";
  width: 9px;
  height: 9px;
  background: #04628c;
  border-radius: 15px;
  box-sizing: border-box;
  position: absolute;
  top: 9px;
  left: -4px;
  z-index: 9;
}
.history-inner ul:after {
  width: 9px;
  height: 9px;
  content: "";
  display: block;
  background-color: #04628c;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: 9px;
  z-index: -1;
  animation: dots 1.5s ease-in-out infinite;
}
.history-inner:nth-child(even) ul:before {
  left: calc(100% + -4px);
}
.history-inner:nth-child(even) ul:after {
  right: -5px;
  left: auto;
}
.history-inner li.year {
  font-family: "Play", sans-serif;
  font-size: 2.125rem;
  line-height: 100%;
  color: #333;
  font-weight: 700;
  text-align: left;
  margin-bottom: 25px;
}
.history-inner li.year:before {
  display: block;
  content: "";
  width: 7%;
  height: 1px;
  background: #ddd;
  position: absolute;
  top: 14px;
  left: 0;
  z-index: -2;
}
.history-inner:nth-child(even) li.year:before {
  left: calc(100% + -47px);
}

.history-guide {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
}
.history-guide:after {
  display: block;
  content: "";
  background: #ddd;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -2;
}
.history-guide li {
}

.history-inner li.year a {
  font-size: 28px;
  line-height: 100%;
  color: #333;
  font-weight: 700;
  text-align: left;
  margin-bottom: 12px;
}
.history-inner li.days {
  position: relative;
}

@media all and (max-width: 980px) {
  .history-inner {
    margin-bottom: 40px;
  }
  .history-inner ul {
    padding: 0 40px 0 0;
  }
  .history-inner:nth-child(odd) ul {
    padding: 0 0 0 40px;
  }
  .history-inner:nth-child(even) ul:after {
  }
  .history-inner li.year {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .history-inner ul:before {
    left: -5px;
  }
  .history-inner ul:after {
    left: -5px;
  }

  .history-inner:nth-child(even) li.year:before {
    left: calc(100% + -35px);
  }
  .history-inner .his_img {
    padding: 0 0 0 40px;
  }
  .history-inner:nth-child(odd) .his_img {
    padding: 0 0 0 110px;
    text-align: left;
  }
}

@media all and (max-width: 680px) {
  .history-guide {
    background-position: left 1px top;
    padding-top: 40px;
    padding-left: 30px;
  }
  .history-guide:before {
    left: 11px;
    transform: translateX(0%);
    width: 9px;
    height: 9px;
  }
  .history-guide:after {
    left: 15px;
  }
  .history-inner {
    margin-bottom: 30px;
  }
  .history-inner ul {
    width: 100%;
    padding: 0 0 0 25px !important;
  }
  .history-inner ul:before {
    top: 4px;
    left: -19px;
  }
  .history-inner ul:after {
    left: -19px;
    top: 4px;
  }
  .history-inner:nth-child(even) ul:after {
    right: auto;
    left: -19px;
  }
  .history-inner li.year {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
  .history-inner li.year:before {
    top: 8px;
    width: 25px;
    left: -10px;
  }

  .history-inner:nth-child(even) ul {
    float: left;
  }

  .history-inner .his_img {
    float: none;
    clear: both;
    width: 100%;
    box-sizing: Border-box;
  }
  .history-inner:nth-child(even) ul:before {
    left: -19px;
  }
  .history-inner:nth-child(even) li.year:before {
    left: 0;
  }
  .history-inner:nth-child(even) li.year {
    text-align: left;
  }
  .history-inner:nth-child(even) ul {
    padding: 0 0 0 40px;
  }

  .history-inner:nth-child(even) .item {
    flex-direction: row;
  }
}
