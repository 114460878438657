/*--------------------------------------------------------------
21. wpo-contact-pg-section
--------------------------------------------------------------*/
.wpo-contact-pg-section {
  padding-bottom: 0px;

  .wpo-contact-title {
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 35px;
      font-size: calc-rem-value(35);
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        font-size: 25px;
        font-size: calc-rem-value(25);
      }
    }
  }

  form input,
  form select,
  form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 25px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
      height: 45px;
    }

    &:focus {
      border-color: $theme-primary-color;
      background: transparent;
    }
  }

  form textarea {
    height: 180px;
    padding-top: 15px;
  }

  form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
      display: inline-block;
      color: #a9a9a9;
      cursor: pointer;
      opacity: 1;
      padding: 6px 25px;
      font-size: 15px;
      font-size: calc-rem-value(15);
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
      position: relative;

      &:focus {
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
      }
    }

    .submit-area {
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;

      @include media-query(767px) {
        margin-bottom: 0;
      }

      .theme-btn {
        border-radius: 0px;
        font-family: $base-font;
        font-size: 16px;

        &:after {
          border-radius: 0px;
        }
      }
    }

    .form-field {
      margin-bottom: 30px;
    }
    .errorMessage {
      color: red;
      margin-top: 5px;
      font-size: 12px;
    }
  }

  form > div {
    margin: 0 15px 25px;

    @include media-query(600px) {
      float: none;
    }
  }

  .office-info {
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-bottom: 60px;
    }

    .col {
      .office-info-item {
        @media (max-width: 1200px) {
          // margin-bottom: 30px;
        }
      }

      &:last-child {
        .office-info-item {
          @media (max-width: 1200px) {
            margin-bottom: 0px;
          }
        }
      }
    }

    .office-info-item {
      text-align: center;
      box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
      padding: 40px;

      .office-info-icon {
        width: 85px;
        height: 85px;
        background: #f5f5f5;
        line-height: 85px;
        text-align: center;
        margin: 0 auto;
        @include rounded-border(50%);

        .icon {
          .fi {
            &:before {
              font-size: 35px;
              line-height: unset;
            }
          }
        }
      }

      .office-info-text {
        h2 {
          font-size: 30px;
          font-weight: 500;
          margin: 20px 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .wpo-contact-form-area {
    padding: 50px;
    background: $white;
    box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
    padding-bottom: 40px;
    margin-bottom: -125px;
    position: relative;
    z-index: 99;

    @media (max-width: 767px) {
      padding: 30px;
      padding-top: 50px;
    }
  }
}

.wpo-contact-map-section {
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 1rem;

  .wpo-contact-map {
    height: 550px;
    position: relative;
    .map-cover {
      align-items: center;
      background: #04628c;
      color: #fff;
      display: flex;
      font-size: 2rem;
      height: 10%;
      justify-content: center;
      left: 0;
      opacity: 0.9;
      position: absolute;
      top: 68%;
      width: 30%;
      z-index: 0;
    }
  }
  .wpo-contact-map:last-child .map-cover {
    left: unset;
    right: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
  }

  h2.hidden {
    display: none;
  }
}

.agreement {
  position: relative !important;
  text-align: center;
  font-size: 0.9rem;
  label {
    cursor: pointer;
  }
  input {
    width: unset !important;
    height: unset !important;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  span {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .privacy-policy-link {
    all: unset;
    font-weight: bold;
    color: $theme-primary-color;
    cursor: pointer;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
