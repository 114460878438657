/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:wght@300;400;500;700;900&display=swap");

/* #### Generated By: http://www.cufonfonts.com #### */

// fonts
$base-font-size: 15;
$base-font: "Roboto", sans-serif;
$heading-font: "PT Serif", serif;

// color
$dark-gray: #0a272c;
$dark-gray2: #14212b;
$body-color: #687693;
$white: #fff;
$offWhite: #e9e1e1;
$light: #cbcbcb;
$black: #000;
$small-black: #333;
$cyan: #848892;

//theme-primary-color, theme-primary-color-s2 color changed
$theme-primary-color: #04628c;
$theme-primary-color-s2: #00bfb2;
$body-bg-color: #fff;
$section-bg-color: #f3f4f4;
$section-bg-color-s2: #f5f5f5;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
