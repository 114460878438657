/* 팝업 컨테이너 */
.popup-container {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 1000;
    width: 80%;
    max-width: 610px;
  }
  
  /* 팝업 스타일 */
  .popup {
    background-color: #1e293b; /* 어두운 블루톤 배경 */
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 약간 강한 그림자 */
    font-family: Arial, sans-serif;
    line-height: 1.6;
    /*background-image: url("../../images/mission-shape.png"); */ /* 이미지 경로 */
    background-size: cover; /* 이미지 크기 조정 */
    background-repeat: no-repeat; /* 반복 방지 */
    background-position: center; /* 배경 중앙 정렬 */
    opacity: 0.9; /* 약간의 투명도 추가 */
  }
  
  /* 제목 스타일 */
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* 하얀색 텍스트 */
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* 부제목 스타일 */
  .sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #d1d5db; /* 밝은 회색 */
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* 내용 텍스트 */
  .content {
    font-size: 14px;
    color: #e5e7eb; /* 회색 텍스트 */
    margin-bottom: 20px;
    text-align: center; /* 텍스트 가운데 정렬 */
  }
  
  /* 날짜 스타일 */
  .date {
    font-size: 14px;
    font-weight: bold;
    color: #d1d5db;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* 섹션 스타일 */
  .section {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: rgba(51, 65, 85, 0.9); /* 투명 배경 */
  }
  
  /* 리스트 스타일 */
  .info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  /* 라벨 텍스트 */
  .info-label {
    font-weight: bold;
    color: #ffffff; /* 하얀색 */
    background-color: #334155; /* 어두운 블루 그레이 */
    border-radius: 12px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 14px;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* 정보 텍스트 */
  .info-text {
    font-size: 14px;
    font-weight: bold;
    color: #f8fafc; /* 밝은 하얀색 */
  }
  
  /* 리스트 항목 */
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* 버튼 스타일 */
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button {
    padding: 10px 15px;
    background-color: #334155; /* 어두운 블루 그레이 */
    color: #f1f5f9; /* 밝은 텍스트 */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin: 5px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #475569; /* hover 효과 */
  }
  
  /* 모바일 화면 대응 */
@media (max-width: 700px) {
    .popup-container {
      width: 90%; /* 너비를 화면에 맞춤 */
      top: 10%; /* 팝업이 화면 상단으로 조금 올라가도록 조정 */
      transform: translate(-50%, 0); /* 수직 방향의 translate 제거 */
      height: auto; /* 높이를 자동으로 조정 */
      max-height: 90vh; /* 최대 높이를 뷰포트 높이에 맞춤 */
      overflow-y: auto; /* 내용이 넘칠 경우 스크롤 활성화 */
    }
  
    .popup {
      padding: 9px; /* 내부 여백 줄이기 */
      border-radius: 8px; /* 모서리를 조금 더 작게 */
    }
  
    .title {
      font-size: 18px; /* 제목 크기를 조금 작게 */
    }
  
    .sub-title {
      font-size: 13px; /* 부제목 크기를 줄임 */
      margin-bottom: 15px;
    }
  
    .content {
      font-size: 10px; /* 본문 텍스트 크기 줄이기 */
    }
  
    .footer {
      flex-direction: column; /* 버튼을 세로로 정렬 */
      gap: 10px; /* 버튼 사이 여백 추가 */
    }
  
    .button {
      width: 100%; /* 버튼이 화면 너비를 채우도록 설정 */
    }
    .info-text {
        font-size: 10px;
      }
    .info-label{
        font-size: 10px;
      }
    .content{
        margin-bottom: 10px;
      }
     .data{
        margin-bottom: 10px;
      }
  }